import { graphql, Link } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import Layout from "../components/layout";
import SEO from "../components/seo"

export default ({ data, pageContext }) => {
  const { frontmatter, excerpt, body } = data.mdx;
  const { previous, next } = pageContext;
  return (
    <Layout>
      <SEO title={frontmatter.title} description={excerpt} />
      <article className="blog-post m-2">
        <header className="mb-4" style={{fontSize: "0.8em"}}>
          <h4>{frontmatter.title}</h4>
          <p className="mb-0">{`Created: ${frontmatter.date}`}</p>
          {frontmatter.update ? <p className="mb-0">{`Updated: ${frontmatter.update}`}</p> : null}
        </header>
        <MDXRenderer>{body}</MDXRenderer>
        <section className="d-flex">
          {previous === false ? null : (
            <>
              {previous && (
                <Link className="blog-previous" to={previous.fields.slug}>
                  <p>Previous</p>
                </Link>
              )}
            </>
          )}
          {next === false ? null : (
            <>
              {next && (
                <Link className="blog-next" to={next.fields.slug}>
                  <p>Next</p>
                </Link>
              )}
            </>
          )}
        </section>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        update(formatString: "MMMM DD, YYYY")
      }
    }
  }
`;